import type { NextPage } from 'next';
import { LoginComponent, LoginServerSideProps } from 'ui';

const LoginPage: NextPage = () => {
	return <LoginComponent />;
};

export const getServerSideProps = LoginServerSideProps;

export default LoginPage;
